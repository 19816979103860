import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import {
  ReportQuestionnaireByCategory,
  SchoolCategory,
  useReportQuestionnaireSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import QuestionnaireBarChart from '../graph/QuestionnaireBarChart'
import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

const { Option } = Select

export type Questionnaire = {
  question: string
  dataKey: string
  answers: string[]
}

export const questionnaires: Questionnaire[] = [
  {
    question: '運動部やスポーツクラブへの所属状況',
    dataKey: 'q2',
    answers: ['所属している', '所属していない'],
  },
  {
    question: '運動・スポーツの実施状況',
    dataKey: 'q3',
    answers: [
      'ほとんど毎日(週3日以上)',
      'ときどき(週1〜2日程度)',
      'ときたま(月1〜3日程度)',
      'しない',
    ],
  },
  {
    question: '1日の運動・スポーツの実施時間',
    dataKey: 'q4',
    answers: [
      '30分未満',
      '30分以上1時間未満',
      '1時間以上2時間未満',
      '2時間以上',
    ],
  },
  {
    question: '朝食の有無',
    dataKey: 'q5',
    answers: ['毎日食べる', '時々欠かす', 'まったく食べない'],
  },
  {
    question: '1日の睡眠時間',
    dataKey: 'q6',
    answers: ['6時間未満', '6時間以上8時間未満', '8時間以上'],
  },
  {
    question: '1日のテレビ(テレビゲーム含む)の視聴時間',
    dataKey: 'q7',
    answers: [
      '1時間未満',
      '1時間以上2時間未満',
      '2時間以上3時間未満',
      '3時間以上',
    ],
  },
]

type ReportQuestionnaireByGrade = {
  schoolCategory: SchoolCategory
  grade: number
  countByAnswer: number[]
}

type ReportQuestionnaireByGender = {
  gender: UserGender
  grades: ReportQuestionnaireByGrade[]
}

export type ReportQuestionnaire = {
  key: string
  genders: ReportQuestionnaireByGender[]
}

type TableProps = {
  prefectureCode: number
  cityCode: number
  year: number
  forPrinting: boolean
}

const ReportQuestionnaireTable = ({
  prefectureCode,
  cityCode,
  year,
  forPrinting,
}: TableProps) => {
  const [
    getReportForMale,
    { data: dataForMale, loading: loadingForMale, called: calledForMale },
  ] = useReportQuestionnaireSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [
    getReportForFemale,
    { data: dataForFemale, loading: loadingForFemale, called: calledForFemale },
  ] = useReportQuestionnaireSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [dataForGraph, setDataForGraph] = useState<ReportQuestionnaire[]>([])

  useEffect(() => {
    if (prefectureCode === 0) return
    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 1,
        },
      },
    })
    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, cityCode, year, getReportForMale, getReportForFemale])

  useEffect(() => {
    if (!dataForMale || !dataForFemale) return
    if (loadingForMale || loadingForFemale) return
    if (
      !dataForMale.reportQuestionnaireSummary?.data ||
      !dataForFemale.reportQuestionnaireSummary?.data
    ) {
      setDataForGraph([])
      return
    }

    const schools = [
      'elementarySchoolReport',
      'juniorHighSchoolReport',
      // 'highSchoolReport',
    ]
    const userGenders = [UserGender.Male, UserGender.Female]
    const data: ReportQuestionnaire[] = questionnaires.map((q) => {
      const genders: ReportQuestionnaireByGender[] = [
        {
          gender: UserGender.Male,
          grades: [],
        },
        {
          gender: UserGender.Female,
          grades: [],
        },
      ]
      userGenders.forEach((g) => {
        const d = g === UserGender.Male ? dataForMale : dataForFemale
        const destGrades = genders.find((gg) => gg.gender === g)
        if (!destGrades) return
        schools.forEach((s) => {
          if (!d.reportQuestionnaireSummary?.data) return
          const targetData: ReportQuestionnaireByCategory =
            d.reportQuestionnaireSummary.data[s]
          targetData.reports?.forEach((r) => {
            const { grade, countByAnswer } = r
            if (!countByAnswer || !countByAnswer[q.dataKey]) return
            let answers = countByAnswer[q.dataKey]

            const total = answers.reduce((p, c) => p + c.v, 0)
            answers = answers.map((v) => ((v.v / total) * 100).toFixed(1))
            let schoolCategory = SchoolCategory.ElementarySchool
            if (s === 'juniorHighSchoolReport')
              schoolCategory = SchoolCategory.JuniorHighSchool
            else if (s === 'highSchoolReport')
              schoolCategory = SchoolCategory.HighSchool
            destGrades.grades.push({
              schoolCategory,
              grade: grade!,
              countByAnswer: answers,
            })
          })
        })
      })
      return {
        key: q.dataKey,
        genders,
      }
    })
    setDataForGraph(data)
  }, [dataForMale, dataForFemale, loadingForMale, loadingForFemale])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (!calledForMale || !calledForFemale) {
    return null
  }

  if (dataForGraph.length === 0) {
    return <NoData />
  }

  return (
    <>
      {dataForGraph.map((q) => {
        const qq = questionnaires.find((v) => v.dataKey === q.key)
        if (!qq) return null
        return (
          <QuestionnaireBarChart
            questionnaire={qq}
            report={q}
            key={q.key}
            forPrinting={forPrinting}
          />
        )
      })}
    </>
  )
}

const ReportQuestionnairePage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8
  const [year, setYear] = useState(2023)

  return (
    <Dashboard
      navbar={<div className="theme-nav-title">児童生徒の生活状況</div>}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div style={{ minWidth: 900 }}>
            <div className="pb-8 space-y-4">
              <Select value={year} onChange={(v) => setYear(v)}>
                <Option value={2010}>2010 ({makeYearName(2010)})</Option>
                <Option value={2011}>2011 ({makeYearName(2011)})</Option>
                <Option value={2012}>2012 ({makeYearName(2012)})</Option>
                <Option value={2013}>2013 ({makeYearName(2013)})</Option>
                <Option value={2014}>2014 ({makeYearName(2014)})</Option>
                <Option value={2015}>2015 ({makeYearName(2015)})</Option>
                <Option value={2016}>2016 ({makeYearName(2016)})</Option>
                <Option value={2017}>2017 ({makeYearName(2017)})</Option>
                <Option value={2018}>2018 ({makeYearName(2018)})</Option>
                <Option value={2019}>2019 ({makeYearName(2019)})</Option>
                <Option value={2020}>2020 ({makeYearName(2020)})</Option>
                <Option value={2021}>2021 ({makeYearName(2021)})</Option>
                <Option value={2022}>2022 ({makeYearName(2022)})</Option>
                <Option value={2023}>2023 ({makeYearName(2023)})</Option>
              </Select>
            </div>

            <ReportQuestionnaireTable
              prefectureCode={prefectureCode}
              cityCode={city.code}
              year={year}
              forPrinting={false}
            />
          </div>

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県{city.name}児童生徒の体力・運動能力調査結果
        </div>
        <div>児童生徒の生活状況</div>
        <ReportQuestionnaireTable
          prefectureCode={prefectureCode}
          cityCode={city.code}
          year={year}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportQuestionnairePage
