import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  ReportTestRankByGrade,
  ReportTestRankSummary,
  useReportTestRankSummaryLazyQuery,
} from '@alpha/core'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

const { Option } = Select

type ReportTableDataProps = {
  data: ReportTestRankByGrade
  className: string
}

const ranks = ['A', 'B', 'C', 'D', 'E']

const ReportTableData = ({ data, className }: ReportTableDataProps) => {
  return (
    <tr className={className}>
      <td className={className}>{data.grade === 0 ? '合計' : data.grade}</td>
      <td className={className}>{data.totalCount}</td>
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_count_${rank}`}>
          {data?.countByRank?.[`rank${rank}`] ?? 0}
        </td>
      ))}
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_rate_${rank}`}>
          {((data?.rateByRank?.[`rank${rank}`] ?? 0) * 100).toFixed(2)}%
        </td>
      ))}
    </tr>
  )
}

const styleForReport = {
  fontSize: 12,
  width: 64,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className={className}>
        <th
          className={className}
          style={{ fontSize: 12, width: 128 }}
          rowSpan={2}
        >
          校種
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          学年
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          実施人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別割合
        </th>
      </tr>
      <tr className={className}>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  className: string
  data: ReportTestRankSummary
}

const TableBody = ({ className, data }: TableBodyProps) => {
  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    // allDayHighSchoolReport,
    // fixTimeHighSchoolReport,
  } = data
  if (
    !elementarySchoolReport?.reports ||
    !juniorHighSchoolReport?.reports
    // !allDayHighSchoolReport?.reports ||
    // !fixTimeHighSchoolReport?.reports
  ) {
    return null
  }
  return (
    <tbody>
      <tr>
        <th
          rowSpan={elementarySchoolReport.reports.length + 1}
          className={className}
        >
          小学校
        </th>
      </tr>
      {elementarySchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={juniorHighSchoolReport.reports.length + 1}
          className={className}
        >
          中学校
        </th>
      </tr>
      {juniorHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      {/* <tr>
        <th
          rowSpan={allDayHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校
        </th>
      </tr>
      {allDayHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={fixTimeHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校(定時制)
        </th>
      </tr>
      {fixTimeHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))} */}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
  cityCode: number
  year: number
}

const makeReportTestRankByGrade = (): ReportTestRankByGrade => {
  return {
    grade: 0,
    totalCount: 0,
    countByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
    rateByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
  }
}

export const ibarakiTotalRank2022 = {
  "鹿嶋市": {
    total: [
      ['elementarySchoolReport', 681, 844, 933, 529, 220, 21.23, 26.32, 29.09, 16.50, 6.86],
      ['juniorHighSchoolReport', 304, 416, 368, 167, 61, 23.10, 31.61, 27.96, 12.69, 4.64]
    ]
  },
  "高萩市": {
    total: [
      ['elementarySchoolReport', 196, 318, 314, 193, 83, 17.75, 28.80, 28.44, 17.48, 7.52],
      ['juniorHighSchoolReport', 162, 167, 128, 64, 21, 29.89, 30.81, 23.62, 11.81, 3.87]
    ]
  },
  "筑西市": {
    total: [
      ['elementarySchoolReport', 742, 1171, 1409, 806, 389, 16.43, 25.92, 31.19, 17.84, 8.61],
      ['juniorHighSchoolReport', 744, 591, 481, 215, 66, 35.48, 28.18, 22.94, 10.25, 3.15]
    ]
  },
  "石岡市": {
    total: [
      ['elementarySchoolReport', 465, 836, 915, 579, 276, 15.14, 27.22, 29.79, 18.85, 8.99],
      ['juniorHighSchoolReport', 219, 363, 410, 263, 112, 16.02, 26.55, 29.99, 19.24, 8.19]
    ]
  },
  "河内町": {
    total: [
      ['elementarySchoolReport', 44, 63, 63, 45, 19, 18.80, 26.92, 26.92, 19.23, 8.12],
      ['juniorHighSchoolReport', 25, 44, 30, 25, 8, 18.94, 33.33, 22.73, 18.94, 6.06]
    ]
  },
  "桜川市": {
    total: [
      ['elementarySchoolReport', 468, 501, 379, 205, 71, 28.82, 30.85, 23.34, 12.62, 4.37],
      ['juniorHighSchoolReport', 310, 270, 191, 89, 25, 35.03, 30.51, 21.58, 10.06, 2.82]
    ]
  },
  "東海村": {
    total: [
      ['elementarySchoolReport', 497, 661, 644, 283, 132, 22.42, 29.82, 29.05, 12.76, 5.95],
      ['juniorHighSchoolReport', 262, 294, 262, 144, 58, 25.69, 28.82, 25.69, 14.12, 5.69]
    ]
  },
  "常陸太田市": {
    total: [
      ['elementarySchoolReport', 430, 552, 462, 199, 74, 25.04, 32.15, 26.91, 11.59, 4.31],
      ['juniorHighSchoolReport', 249, 241, 183, 103, 31, 30.86, 29.86, 22.68, 12.76, 3.84]
    ],
  },
  "古河市": {
    total: [
      ['elementarySchoolReport', 855, 1661, 2068, 1268, 567, 13.32, 25.88, 32.22, 19.75, 8.83],
      ['juniorHighSchoolReport', 707, 800, 760, 478, 163, 24.31, 27.51, 26.13, 16.44, 5.61]
    ],
  },
}

const ReportTestTable = ({ prefectureCode, cityCode, year }: TableProps) => {
  const [getReportForMale, { data: dataForMale, loading: loadingForMale }] =
    useReportTestRankSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })
  const [
    getReportForFemale,
    { data: dataForFemale, loading: loadingForFemale },
  ] = useReportTestRankSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [dataForTotal, setDataForTotal] = useState<ReportTestRankSummary>()
  useEffect(() => {
    if (prefectureCode === 0) return
    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 1,
        },
      },
    })
    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, cityCode, year, getReportForMale, getReportForFemale])

  const cityName = getCity()?.name

  useEffect(() => {
    if (!dataForMale || !dataForFemale) return
    const totalData: ReportTestRankSummary = {
      elementarySchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      juniorHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      // allDayHighSchoolReport: {
      //   reports: [makeReportTestRankByGrade()],
      // },
      // fixTimeHighSchoolReport: {
      //   reports: [makeReportTestRankByGrade()],
      // },
    }
    const reports = [dataForMale, dataForFemale]
    const schools = [
      'elementarySchoolReport',
      'juniorHighSchoolReport',
      // 'allDayHighSchoolReport',
      // 'fixTimeHighSchoolReport',
    ]
    reports.forEach((d) => {
      schools.forEach((s) => {
        if (!d.reportTestRankSummary?.data) return
        const targetData = d.reportTestRankSummary?.data[s].reports
        const destData = totalData[s].reports[0]
        targetData.forEach((t) => {
          destData.totalCount += t.totalCount
          ranks.forEach((rank) => {
            destData.countByRank[`rank${rank}`] += t?.countByRank?.[`rank${rank}`] ?? 0
          })
        })
        ranks.forEach((rank, index) => {
          destData.rateByRank[`rank${rank}`] = destData.totalCount
            ? destData.countByRank[`rank${rank}`] / destData.totalCount
            : 0
          if (year === 2022 && ibarakiTotalRank2022[cityName]?.total) {
            const totalData = ibarakiTotalRank2022[cityName]?.total
              ?.find(r => r[0] === s) ?? 0
            destData.rateByRank[`rank${rank}`] = (totalData?.[index + 6] ?? 0) / 100
          }
        })
      })
    })
    if (cityName === '桜川市' && year === 2022) {
      totalData.elementarySchoolReport.reports[0].totalCount = 1678
    }
    setDataForTotal(totalData)
    console.log(totalData)
  }, [dataForMale, dataForFemale, setDataForTotal, cityName, year])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (
    !dataForMale?.reportTestRankSummary?.data ||
    !dataForFemale?.reportTestRankSummary?.data
  ) {
    return <NoData />
  }
  if (!dataForTotal) {
    return null
  }
  const maleData = dataForMale.reportTestRankSummary.data
  const femaleData = dataForFemale.reportTestRankSummary.data

  return (
    <>
      <div>男子</div>
      <table className="table border-collapse">
        <TableHeader className="table-border-male" />
        <TableBody className="table-border-male" data={maleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        女子
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-female" />
        <TableBody className="table-border-female" data={femaleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        合計
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-total" />
        <TableBody className="table-border-total" data={dataForTotal} />
      </table>
    </>
  )
}

const ReportTestRankPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8
  const [year, setYear] = useState(2023)

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数及びその割合</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="print:hidden">
        <Card>
          <div className="space-y-4 space-x-4 pb-8">
            <Select value={year} onChange={(v) => setYear(v)}>
              <Option value={2010}>2010 ({makeYearName(2010)})</Option>
              <Option value={2011}>2011 ({makeYearName(2011)})</Option>
              <Option value={2012}>2012 ({makeYearName(2012)})</Option>
              <Option value={2013}>2013 ({makeYearName(2013)})</Option>
              <Option value={2014}>2014 ({makeYearName(2014)})</Option>
              <Option value={2015}>2015 ({makeYearName(2015)})</Option>
              <Option value={2016}>2016 ({makeYearName(2016)})</Option>
              <Option value={2017}>2017 ({makeYearName(2017)})</Option>
              <Option value={2018}>2018 ({makeYearName(2018)})</Option>
              <Option value={2019}>2019 ({makeYearName(2019)})</Option>
              <Option value={2020}>2020 ({makeYearName(2020)})</Option>
              <Option value={2021}>2021 ({makeYearName(2021)})</Option>
              <Option value={2022}>2022 ({makeYearName(2022)})</Option>
              <Option value={2023}>2023 ({makeYearName(2023)})</Option>
            </Select>
          </div>

          <ReportTestTable
            prefectureCode={prefectureCode}
            cityCode={city.code}
            year={year}
          />

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県{city.name}児童生徒の体力・運動能力調査結果
        </div>
        <div>総合評価段階別人数及びその割合</div>
        <ReportTestTable
          prefectureCode={prefectureCode}
          cityCode={city.code}
          year={year}
        />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankPage
