import { useEffect, useState } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { Questionnaire, questionnaires } from './ReportQuestionnairePage'
import { Card } from '@alpha/components'
import { Select, Checkbox } from 'antd'
import {
  SchoolCategory,
  useReportCrossAnalysisSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import CrossAnalysisLineChart from '../graph/CrossAnalysisLineChart'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { getCity } from '../../data/data'

const { Option } = Select

const scoreTypes = {
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
  totalScore: '得点合計',
}

const schoolCategories = [
  SchoolCategory.ElementarySchool,
  SchoolCategory.JuniorHighSchool,
  // SchoolCategory.HighSchool,
]

type ReportScoreByAnswer = {
  answer: string
  score: number
}

type ReportScoreByGrade = {
  schoolCategory: SchoolCategory
  grade: number
  answers: ReportScoreByAnswer[]
}

type ReportScoreByGender = {
  gender: UserGender
  grades: ReportScoreByGrade[]
}

export type ReportScoreByScoreType = {
  scoreType: string
  genders: ReportScoreByGender[]
}

type ChartsProps = {
  prefectureCode: number
  cityCode: number
  schoolCategories: SchoolCategory[]
  year: number
  question: Questionnaire | undefined
  forPrinting: boolean
}

const CrossAnalysisLineCharts = ({
  prefectureCode,
  cityCode,
  schoolCategories,
  year,
  question,
  forPrinting,
}: ChartsProps) => {
  const [targetData, setTargetData] = useState<ReportScoreByScoreType[]>([])

  const [
    getReportForMale,
    { data: dataForMale, loading: loadingForMale, called: calledForMale },
  ] = useReportCrossAnalysisSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [
    getReportForFemale,
    { data: dataForFemale, loading: loadingForFemale, called: calledForFemale },
  ] = useReportCrossAnalysisSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (prefectureCode === 0) return

    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 1,
        },
      },
    })
    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          cityCode,
          year,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, cityCode, year, getReportForMale, getReportForFemale])

  useEffect(() => {
    if (loadingForMale || loadingForFemale) return
    if (!dataForMale || !dataForFemale) return
    if (!question) return

    let maleReports = dataForMale.reportCrossAnalysisSummary?.data?.reports
    let femaleReports = dataForFemale.reportCrossAnalysisSummary?.data?.reports
    let maleReport = maleReports?.find((r) => r.dataKey === question.dataKey)
    let femaleReport = femaleReports?.find(
      (r) => r.dataKey === question.dataKey,
    )
    if (!maleReport) return
    if (!femaleReport) return

    let computedData: any = Object.keys(scoreTypes).map((scoreType) => {
      let maleGrades = maleReport!.grades!.map((g) => {
        let answers = g.answers!.filter(a => question.answers.length >= a.answer).map((a) => {
          let answer = question.answers[a.answer! - 1].replace(/\(.*\)/i, '')

          let score = +(a
            .averages!.find((av) => av.scoreType === scoreType)!
            .score ?? 0).toFixed(1)
          return {
            answer,
            score,
          }
        })
        return {
          schoolCategory: g.schoolCategory,
          grade: g.grade,
          answers,
        }
      })

      let femaleGrades = femaleReport!.grades!.map((g) => {
        let answers = g.answers!.filter(a => question.answers.length >= a.answer).map((a) => {
          let answer = question.answers[a.answer! - 1].replace(/\(.*\)/i, '')

          let score = +(a
            .averages!.find((av) => av.scoreType === scoreType)!
            .score ?? 0).toFixed(1)
          return {
            answer,
            score,
          }
        })
        return {
          schoolCategory: g.schoolCategory,
          grade: g.grade,
          answers,
        }
      })

      maleGrades = maleGrades.slice().sort((a, b) => {
        let gToInt = (cat, g) => {
          if (cat === SchoolCategory.ElementarySchool) {
            return g
          } else if (cat === SchoolCategory.JuniorHighSchool) {
            return 6 + g
          } else {
            return 9 + g
          }
        }

        let _a = gToInt(a.schoolCategory, a.grade)
        let _b = gToInt(b.schoolCategory, b.grade)

        return _a - _b
      })

      femaleGrades = femaleGrades.slice().sort((a, b) => {
        let gToInt = (cat, g) => {
          if (cat === SchoolCategory.ElementarySchool) {
            return g
          } else if (cat === SchoolCategory.JuniorHighSchool) {
            return 6 + g
          } else {
            return 9 + g
          }
        }

        let _a = gToInt(a.schoolCategory, a.grade)
        let _b = gToInt(b.schoolCategory, b.grade)

        return _a - _b
      })

      let male = {
        gender: 'MALE',
        grades: maleGrades,
      }

      let female = {
        gender: 'FEMALE',
        grades: femaleGrades,
      }

      return {
        scoreType,
        genders: [male, female],
      }
    })

    setTargetData(computedData)
  }, [question, dataForMale, dataForFemale, loadingForMale, loadingForFemale])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (!calledForMale || !calledForFemale) {
    return null
  }

  return (
    <>
      {targetData.map((d) => (
        <CrossAnalysisLineChart
          data={d}
          visibleCategories={schoolCategories}
          key={d.scoreType}
          forPrinting={forPrinting}
        />
      ))}
    </>
  )
}

const ReportCrossAnalysisGraphPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const city = getCity()
  const prefectureCode = 8

  const [year, setYear] = useState(2023)
  const [selectedQuestion, setSelectedQuestion] = useState('q2')
  const [question, setQuestion] = useState<Questionnaire>()

  const [checkedSchoolCategories, setCheckedSchoolCategories] =
    useState(schoolCategories)

  const handleChangeQuestion = (v) => setSelectedQuestion(v)

  const handleSchoolCategories = (v) => {
    setCheckedSchoolCategories(v)
  }

  useEffect(() => {
    const q = questionnaires.find((q) => q.dataKey === selectedQuestion)
    if (q) {
      setQuestion(q)
    }
  }, [selectedQuestion])

  return (
    <Dashboard
      navbar={<div className="theme-nav-title">アンケート回答別クロス集計</div>}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div style={{ width: 900 }}>
            <div className="pb-8 space-y-4">
              <Select value={year} onChange={(v) => setYear(v)}>
                <Option value={2010}>2010 ({makeYearName(2010)})</Option>
                <Option value={2011}>2011 ({makeYearName(2011)})</Option>
                <Option value={2012}>2012 ({makeYearName(2012)})</Option>
                <Option value={2013}>2013 ({makeYearName(2013)})</Option>
                <Option value={2014}>2014 ({makeYearName(2014)})</Option>
                <Option value={2015}>2015 ({makeYearName(2015)})</Option>
                <Option value={2016}>2016 ({makeYearName(2016)})</Option>
                <Option value={2017}>2017 ({makeYearName(2017)})</Option>
                <Option value={2018}>2018 ({makeYearName(2018)})</Option>
                <Option value={2019}>2019 ({makeYearName(2019)})</Option>
                <Option value={2020}>2020 ({makeYearName(2020)})</Option>
                <Option value={2021}>2021 ({makeYearName(2021)})</Option>
                <Option value={2022}>2022 ({makeYearName(2022)})</Option>
                <Option value={2023}>2023 ({makeYearName(2023)})</Option>
              </Select>

              <br />

              <Select
                value={selectedQuestion}
                onChange={handleChangeQuestion}
                style={{ width: 320 }}
              >
                {questionnaires.map((q) => (
                  <Option value={q.dataKey} key={q.dataKey}>
                    {q.question}
                  </Option>
                ))}
              </Select>

              <div>
                <Checkbox.Group
                  value={checkedSchoolCategories}
                  onChange={handleSchoolCategories}
                >
                  <Checkbox
                    value={SchoolCategory.ElementarySchool}
                    className="dark:text-white"
                  >
                    小学校
                  </Checkbox>
                  <Checkbox
                    value={SchoolCategory.JuniorHighSchool}
                    className="dark:text-white"
                  >
                    中学校
                  </Checkbox>
                  {/* <Checkbox
                    value={SchoolCategory.HighSchool}
                    className="dark:text-white"
                  >
                    高等学校
                  </Checkbox> */}
                </Checkbox.Group>
              </div>
            </div>

            <CrossAnalysisLineCharts
              prefectureCode={prefectureCode}
              cityCode={city.code}
              schoolCategories={checkedSchoolCategories}
              year={year}
              question={question}
              forPrinting={false}
            />
          </div>

          <ul>
            <li>
              ※
              小学校の項目は、小学校・義務教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              中学校の項目は、中学校・義務教育学校（後期課程）・中等教育学校（前期課程）のデータを含みます。
            </li>
            <li>
              ※
              高等学校の項目は、高等学校・中等教育学校（後期課程）のデータを含みます。
            </li>
            <li>
              ※
              実施人数には未実施種目がある児童生徒も含みます。
            </li>
          </ul>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県{city.name}児童生徒の体力・運動能力調査結果
        </div>
        <div>アンケート回答別クロス集計</div>
        <div>
          {questionnaires.find((q) => q.dataKey === selectedQuestion)!.question}
        </div>
        <CrossAnalysisLineCharts
          prefectureCode={prefectureCode}
          cityCode={city.code}
          schoolCategories={checkedSchoolCategories}
          year={year}
          question={question}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportCrossAnalysisGraphPage
